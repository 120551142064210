
<template>
<div>
    <b-card
     
     
    >
        <b-row>
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
                <label>Show</label>
                <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                />
                    <b-button
            variant="primary"
            :to="{ name: 'crm-campaign-create'}"
        >
            Add Campaign
        </b-button>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              
            </div>
          </b-col>
        </b-row>
          <b-table
        ref="refListTable"
        class="position-relative mt-2"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(created_by)="data">
            {{ data.item.firstname }} {{ data.item.lastname }}

        </template>
         <template #cell(title)="data">
          <router-link :to="{ name: 'crm-campaign-users', params: { id: data.item.id } }" >{{ data.item.title }}</router-link>
            
             
        </template>
        <template #cell(action)="data">
         
          <b-button
           @click="deleteconfirm(data.item.id)"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="outline-danger"
      pill
      v-if="data.item.status != 'completed'" 
    >
    <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
    </b-button>
             
        </template>
      </b-table>
    </b-card>
</div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,BCardHeader,BCardBody,BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useCampaignList from './useCampaignList';
import crmStoreModule from '../crmStoreModule'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import Button from '../../components/button/Button.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
    components: {
        useCampaignList,
        ValidationProvider, 
        ValidationObserver,
        crmStoreModule,
        BCard, BRow, BCol,BFormGroup , BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,
        vSelect,
        BCardHeader,BCardBody,BOverlay,
        BCardActions,
        Button
    },
    setup() {
        const CRM_APP_STORE_MODULE_NAME = 'app-crm';
        if (!store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.registerModule(CRM_APP_STORE_MODULE_NAME, crmStoreModule);
        onUnmounted(() => {
            if (store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.unregisterModule(CRM_APP_STORE_MODULE_NAME)
        })
        const {
            refListTable,
           
            tableColumns,
            perPage,
            groupQuery,
            totals,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            dataMeta,
            fetchData,
            deleteData
            
        } = useCampaignList();
        return {
            refListTable,
           
            tableColumns,
            perPage,
            groupQuery,
            totals,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            dataMeta,
            fetchData,
            deleteData
        }
        
    },
     directives: {
      Ripple,
    },
    methods:{
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Email Template ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(value => {
                if(value === true){
                    this.deleteData(id)
                }
               
            })
        
        }
    },
}
</script>
